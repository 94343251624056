import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import axios from 'axios';

import Layout from 'components/Layout';
import Meta from 'components/Meta';

import 'styles/usecases.scss';
import 'styles/bug-tracking.scss';

const Ebook = ({ location }) => {
  const [user, setUser] = useState({
    userName: '',
    email: '',
  });
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const onInputChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { userName, email } = user;

    (async () => {
      try {
        setSending(true);
        const response = await axios.post(
          'https://us-central1-rally-brucira.cloudfunctions.net/sendEbook',
          {
            userName,
            email,
          },
        );
        if (response.status === 200) {
          setUser({
            userName: '',
            email: '',
          });
          setSuccess(true);
          setSending(false);
        }
      } catch (err) {
        setSending(false);
      }
    })();
  };

  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Layout location={location} hideTopBar>
      <Meta
        title="Unlock Success with Website Redesign: An In-depth Guide | ebook | ruttl"
        description="Discover the power of website redesign in our eBook. Explore proven strategies to revamp your online presence for enhanced user experiences and revenue growth."
        url="https://ruttl.com/video-annotation-tool/"
      />

      <main className="styled-main">
        <section className="features-hero">
          <div className="container">
            <div className="hero-content">
              <div className="hero-desc ebook">
                <div className="sub-product sub-product--pdf">
                  <img src="/assets/img/add-plugin.svg" alt="" width={18} />
                  <span>GET YOUR FREE EBOOK</span>
                </div>
                <h1>
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <span className="blue-text">Website Redesign:</span> A fresh
                  look at how to revamp
                </h1>
                <p
                  className="sub-text"
                  style={{
                    maxWidth: '100%',
                  }}>
                  With our playbook, &quot;Redesign Your Website,&quot; unlock
                  the full potential of your website for higher revenue,
                  enhanced user experiences, and digital success in the
                  fast-paced online world.
                </p>

                <button
                  type="button"
                  style={{
                    padding: '12px 48px',
                    marginTop: 20,
                  }}
                  onClick={scrollToForm}
                  rel="noopener noreferrer"
                  className="button">
                  Get ebook for free
                </button>
                <ul className="stats">
                  <li>
                    <span className="stat-title">53</span>
                    <span className="stat-text">No. of pages</span>
                  </li>
                  <li>
                    <span className="stat-title">25 mins</span>
                    <span className="stat-text">Completion time</span>
                  </li>
                  <li>
                    <span className="stat-title">English</span>
                    <span className="stat-text">Language</span>
                  </li>
                </ul>
              </div>
              <div className="hero-img ebook">
                <img src="/assets/img/ruttl-ebook.webp" alt="ebook" />
              </div>
            </div>
          </div>
          <div className="ebook-bottom hidden-mob"></div>
        </section>

        <div className="usecases-styled-main">
          <section
            className="section-gap "
            style={{
              background: '#f4f5ff',
            }}>
            <div className="container">
              <div className="ebook">
                <div className="details">
                  <h2>Why should someone read this book?</h2>
                  <ul>
                    <li>
                      <img
                        src="/assets/img/blue-tick.svg"
                        alt=""
                        width={28}
                        height={28}
                      />
                      <span>
                        <span className="bold">Revenue Boost: </span>
                        Learn how website optimization directly correlates with
                        increased revenue for businesses.
                      </span>
                    </li>
                    <li>
                      <img
                        src="/assets/img/blue-tick.svg"
                        alt=""
                        width={28}
                        height={28}
                      />
                      <span>
                        <span className="bold">First Impressions Matter: </span>
                        Understand how your website serves as the digital face
                        of your brand and its impact on customer perceptions.
                      </span>
                    </li>
                    <li>
                      <img
                        src="/assets/img/blue-tick.svg"
                        alt=""
                        width={28}
                        height={28}
                      />
                      <span>
                        <span className="bold">
                          User Experience Equals Conversions:&nbsp;
                        </span>
                        Discover how a seamless user experience can
                        significantly enhance conversion rates.
                      </span>
                    </li>
                    <li>
                      <img
                        src="/assets/img/blue-tick.svg"
                        alt=""
                        width={28}
                        height={28}
                      />
                      <span>
                        <span className="bold">Speed Matters: </span>
                        Learn why the need for speed is crucial in retaining and
                        engaging website visitors.
                      </span>
                    </li>
                    <li>
                      <img
                        src="/assets/img/blue-tick.svg"
                        alt=""
                        width={28}
                        height={28}
                      />
                      <span>
                        <span className="bold">Stay Competitive: </span>
                        Find out how website revamping can help your business
                        stand out in the crowded digital landscape and ensure a
                        competitive edge.
                      </span>
                    </li>
                  </ul>
                </div>
                <form
                  className="ebook-form"
                  ref={formRef}
                  onSubmit={(e) => handleSubmit(e)}>
                  <h2>Get your free ebook</h2>
                  <div className="form-details">
                    <label htmlFor="name">
                      Name
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="userName"
                      value={user.userName}
                      onChange={onInputChange}
                      required
                      placeholder="Enter name"
                    />

                    <label htmlFor="email">
                      Work Email Address
                      <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={user.email}
                      onChange={onInputChange}
                      placeholder="Enter work email address"
                      required
                    />
                  </div>

                  {success && (
                    <span
                      style={{
                        color: '#47c998',
                        display: 'block',
                        marginBottom: 10,
                      }}>
                      Email sent successfully to your inbox
                    </span>
                  )}
                  <button type="submit" className="button">
                    {sending ? 'Sending...' : 'Send to my inbox'}
                  </button>
                </form>
              </div>
            </div>
          </section>
          <section className="section-gap">
            <div className="container">
              <div className="founders-message">
                <span>FROM THE FOUNDERS</span>
                <h2>Message to all the readers</h2>
                <div className="message">
                  <div className="message-left">
                    <img src="/assets/img/founders.webp" alt="" />
                    <p>
                      In today&apos;s competitive digital landscape, the
                      significance of having a well-designed website and a
                      strong online presence cannot be overstated. Your website
                      often serves as the very essence of your brand, shaping
                      the way people perceive and engage with your business. If
                      you&apos;re considering a website redesign, this guidebook
                      is tailor-made for you.
                    </p>
                  </div>
                  <div className="message-right">
                    <p>
                      Drawing from our years of experience as the founders of
                      both Brucira and ruttl, we&apos;ve dedicated a
                      considerable portion of our careers to the art of
                      designing and developing engaging new digital identities
                      for various brands. We&apos;ve witnessed firsthand the
                      transformative power of a well-optimized website,
                      propelling businesses towards remarkable success and
                      growth.
                    </p>

                    <p>
                      This eBook serves as a curated collection of our insights,
                      honed through years of work in this dynamic industry.
                      It&apos;s not just a guide; it&apos;s an ally on your
                      journey to redefining your digital presence and unlocking
                      the full potential of your online platform. We hope this
                      ebook will be a valuable resource as you embark on your
                      transformative journey of website redesign. Welcome, and
                      may your digital evolution be both rewarding and
                      enlightening.
                    </p>
                    <br />
                    <p className="right">
                      Harsh Vijay & Siddhita Upare
                      <br />
                      Co-founders: Brucira and ruttl
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

Ebook.propTypes = {
  location: PropTypes.object,
};

export default Ebook;
